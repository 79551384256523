import { SELECTOR } from "@/ts/const";

const Toast = {
  show(message: string, duration: number = 2000) {
    const toastElement = document.querySelector(
      `#${SELECTOR.MODAL}`
    ) as HTMLElement;

    // TODO: toast Element 생성하기
    if (!toastElement) return;
    toastElement.innerText = message;
    toastElement.classList.remove("none");
    toastElement.classList.add("toast");
    // document.body.appendChild(toastElement);

    // setTimeout(() => {
    //   toastElement.style.display = "none";
    //   document.body.removeChild(toastElement);
    // }, duration); // ✅ `duration` 사용
  },
};

export default Toast;
